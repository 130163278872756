import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { resetPassword } from "../../features/user/userSlice";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const validateLink = () => {
    const userId = searchParams.get("userId");
    const secret = searchParams.get("secret");

    if (!userId || !secret) {
      return null;
    } else {
      return { userId, secret };
    }
  };

  const validateInputs = () => {
    let isValid = true;

    if (!password || password.length < 8) {
      setPasswordError(true);
      setErrorMessage("Password must be at least 8 characters long.");
      isValid = false;
    } else {
      setPasswordError(false);
      setErrorMessage("");
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError(true);
      setErrorMessage("Passwords do not match.");
      isValid = false;
    } else {
      setConfirmPasswordError(false);
    }

    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const linkData = validateLink();
    if (!linkData) {
      setSuccess(false);
      setErrorMessage("Invalid link.");
      return;
    }

    const { userId, secret } = linkData;

    const validInputs = validateInputs();
    if (!validInputs) return;

    try {
      await dispatch(
        resetPassword({ userId, secret, password, confirmPassword })
      ).unwrap();

      setSuccess(true);
      setPassword("");
      setConfirmPassword("");
    } catch (err) {
      setErrorMessage(
        err.message || "Failed to reset password. Please try again."
      );
      setSuccess(false);
      setPassword("");
      setConfirmPassword("");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "background.default",
      }}
    >
      <Card
        sx={{
          maxWidth: 400,
          p: 3,
          boxShadow:
            "0px 4px 20px rgba(0, 0, 0, 0.1), 0px 8px 40px rgba(0, 0, 0, 0.05)",
        }}
        component="form"
        onSubmit={handleSubmit}
      >
        <Typography variant="h5" gutterBottom>
          Reset Your Password
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <FormControl sx={{ mb: 2, width: "100%" }}>
          <FormLabel htmlFor="password">New Password</FormLabel>
          <TextField
            id="password"
            name="password"
            type="password"
            placeholder="Enter new password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={passwordError}
            helperText={passwordError && errorMessage}
            fullWidth
          />
        </FormControl>
        <FormControl sx={{ mb: 2, width: "100%" }}>
          <FormLabel htmlFor="confirm-password">Confirm Password</FormLabel>
          <TextField
            id="confirm-password"
            name="confirm-password"
            type="password"
            placeholder="Confirm new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={confirmPasswordError}
            helperText={confirmPasswordError && "Passwords do not match"}
            fullWidth
          />
        </FormControl>
        {errorMessage && (
          <Typography color="error" variant="body2" sx={{ mb: 2 }}>
            {errorMessage}
          </Typography>
        )}
        {success && (
          <Typography variant="body2" color="success.main" sx={{ mb: 2 }}>
            Successfully reset password.
          </Typography>
        )}
        {success ? (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={() => {
              navigate("/sign-in", { replace: true });
            }}
          >
            Sign In
          </Button>
        ) : (
          <Button type="submit" fullWidth variant="contained">
            Reset Password
          </Button>
        )}
      </Card>
    </Box>
  );
}

export default ResetPassword;
