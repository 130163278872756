// TODO: these fields plus the date is all I want to render on frontend for now
// [ {
//   "resource_state" : 2,
//   "athlete" : {
//     "resource_state" : 2,
//     "firstname" : "Peter",
//     "lastname" : "S."
//   },
//   "name" : "World Championship",
//   "distance" : 2641.7,
//   "moving_time" : 577,
//   "elapsed_time" : 635,
//   "total_elevation_gain" : 8.8,
//   "type" : "Ride",
//   "sport_type" : "MountainBikeRide",
//   "workout_type" : null
// } ]

// TODO need to check if have already authorized strava and thus have data in strava_profile collection ...
// TODO use client side api to query appwrite strava_profile_collection and populate strava slice .... athleteInfo
// TODO then can query here and see if athlete acitivity data exists

import React, { useEffect } from "react";
import Navbar from "../components/navigation/Navbar";
import { useSelector, useDispatch } from "react-redux";
import {
  selectActivities,
  selectAthlete,
  getStravaProfileCol,
  getStravaActivityCol,
} from "../features/strava/stravaSlice";
import { selectUser } from "../features/user/userSlice";

function AuthedHome() {
  const dispatch = useDispatch();
  const athlete = useSelector(selectAthlete);
  const activities = useSelector(selectActivities);
  const userId = useSelector(selectUser).$id;

  console.log("AuthedHome: userId: ", userId);

  useEffect(() => {
    const getData = async () => {
      if (!userId) {
        console.error("User ID is missing.");
        return;
      }

      if (!athlete) {
        try {
          console.log("dispatching getStravaProfileCol ...");
          await dispatch(getStravaProfileCol({ userId })).unwrap();
        } catch (err) {
          console.error("Error getting athlete data : ", err);
          alert("Error getting athlete data."); // TODO state error and errorMessage variable.
        }
      }
      if (!activities) {
        try {
          console.log("dispatching getStravaActivityCol ...");
          await dispatch(getStravaActivityCol({ userId })).unwrap();
        } catch (err) {
          console.error("Error getting activities data : ", err);
          alert("Error getting activities data.");
        }
      }
    };

    getData();
  }, [athlete, activities, userId]);

  // Note: user/athlete redirected to accept scopes/give permission to my app
  // http://www.strava.com/oauth/authorize?client_id=[YOUR_ID_123]&response_type=code&redirect_uri=http://localhost/exchange_token&approval_prompt=force&scope=activity:read_all

  //  Note: activity:read_all
  //  The same access as activity:read, plus
  //  privacy zone data and access to read the user's activities with visibility set to Only You

  const connectToStrava = () => {
    const clientId = process.env.REACT_APP_STRAVA_CLIENT_ID;
    if (process.env.NODE_ENV === "development") {
      console.log("clientId: ", clientId);
    }
    const redirectUri =
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000/registered/strava-callback"
        : "https://sunnycodesapp.com/registered/strava-callback";

    const requiredScope = "activity:read_all";
    const authStravaAccessUrl = `https://www.strava.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&approval_prompt=auto&scope=${requiredScope}`;

    window.location.href = authStravaAccessUrl;
  };

  return (
    <>
      <Navbar />
      <p>Verified User Home Page</p>
      {Array.isArray(activities) && activities.length > 0 ? (
        <>
          <p>Your latest activities</p>
          <ul>
            {activities.map((activity) => (
              <li key={activity.$id}>
                Date: {activity.startDate}| Name: {activity.name} | Distance:{" "}
                {activity.distance} | Elapsed Time: {activity.elapsedTime}
              </li>
            ))}
          </ul>
        </>
      ) : (
        <p>No activities found.</p>
      )}
      {/* TODO This application requires access to your Strava data like profile info and activity data. */}
      {/* TODO In Strava if you make activities only visible to yoursel, data like location and map are not provided to this app by Strava and thus remain private. */}
      {!athlete && <button onClick={connectToStrava}>Authorize Strava</button>}
    </>
  );
}

export default AuthedHome;
