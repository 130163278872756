import React, { useEffect } from "react";
import Navbar from "../navigation/Navbar";
import { useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  getAccessRefresh,
  selectAthlete,
  getStravaActivities,
} from "../../features/strava/stravaSlice";

// http://localhost:3000/registered/strava-callback?state=&code=a367e2d083138af13fa5a3ac0532291444512740&scope=read,activity:read_all

function StravaCallback() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getStravaData = async () => {
      const code = searchParams.get("code");
      const stravaAuthError = searchParams.get("error"); // Note: ?state=error ...?
      if (stravaAuthError) {
        console.error("Strava authorization error: ", stravaAuthError);
        alert("Strava Authorization Error.");
        navigate("/registered/home", { replace: true });
      }
      try {
        if (code) {
          const response = await dispatch(getAccessRefresh({ code })).unwrap();
          console.log("Successfully fetched Strava tokens.");
          console.log("StravaCallback: response: ", response);

          if (response) {
            await dispatch(getStravaActivities({})).unwrap();
            console.log("Successfully fetched Strava activities.");
          }
          if (!response) {
            throw new Error("Failed to fetched Strava tokens.");
          }

          navigate("/registered/home", { replace: true });
        }
      } catch (err) {
        console.error("Strava error: ", err);
        alert("Strava error.");
        navigate("/registered/home", { replace: true });
      }
    };
    getStravaData();
  }, []);
  return (
    <>
      <Navbar />
      <p>Strava Call Back Page</p>
      <p>Completing Authorization ...</p>
    </>
  );
}

export default StravaCallback;
