import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/user/userSlice";
import stravaReducer from "../features/strava/stravaSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    strava: stravaReducer,
  },
});
