import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { selectUser } from "../user/userSlice";
import { databases } from "../../appwrite";
import { Query } from "appwrite";

const initialState = {
  status: "idle", // 'idle' | 'loading' | 'error'
  error: null,
  athleteInfo: null, // {}
  activities: null,
};

// TODO use client side api to query strava_profile_collection
// https://github.com/divanov11/Appwrite-React-quickstart/blob/main/Part-3/src/appwrite/databases.js
// export const getStravaAthleteInfo = createAsyncThunk();

export const getAccessRefresh = createAsyncThunk(
  "strava/getAccessRefresh",
  async ({ code }, { rejectWithValue, getState }) => {
    try {
      const reduxState = getState();
      const userId = selectUser(reduxState).$id;
      console.log("getAccessRefresh: userId: ", userId);

      if (!userId) {
        throw new Error("User id not found in state.");
      }

      const response = await axios.get(
        `https://6768054a05a34ff9b11e.functions.mybackend.sunnycodesapp.com`,
        {
          // TODO pass in body instead of params ... need to edit backend fn
          params: { code, userId },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return response.data; // Note: this contains the athlete info and tokens
    } catch (err) {
      console.log("getAccessRefresh: err: ", err);
      const errorMessage =
        err.response?.data?.error || err.message || "Something went wrong.";
      return rejectWithValue(errorMessage);
    }
  }
);

export const getStravaActivities = createAsyncThunk(
  "strava/getStravaActivities",
  async ({}, { rejectWithValue, getState }) => {
    try {
      const reduxState = getState();
      // Note: correct use of $id which in Appwrite Auth service is user ID not doc id
      // state.user.userInfo.$id
      const userId = selectUser(reduxState).$id;
      console.log("getStravaActivities: userId: ", userId);
      if (!userId) {
        throw new Error("User id not found in state.");
      }

      // TODO this means uer not logged in! logout ... navigate to home

      const response = await axios.get(
        // TODO function needs to get athlete data; save to collection and send response to frontend
        `https://67716f7879f774f58b04.functions.mybackend.sunnycodesapp.com`,
        {
          // TODO pass in body instead of params ... need to edit backend fn
          params: { userId },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("getStravaActivities: response.data: ", response.data);
      return response.data;
    } catch (err) {
      console.log("getStravaActivities: err: ", err);
      const errorMessage =
        err.response?.data?.error || err.message || "Something went wrong.";
      return rejectWithValue(errorMessage);
    }
  }
);

export const getStravaProfileCol = createAsyncThunk(
  "strava/getStravaProfileCol",
  async ({ userId }, { rejectWithValue }) => {
    try {
      if (!userId) {
        throw new Error("User id not provided to getStravaProfileCol.");
      }

      const response = await databases.listDocuments(
        process.env.REACT_APP_APPWWRITE_DB_ID, // databaseId
        process.env.REACT_APP_APPWWRITE_STRAVA_PROFILE_ID, // collectionId
        [Query.equal("appUserId", userId)]
      );
      return response.documents[0];
    } catch (err) {
      console.log("getStravaProfileCol: err: ", err);
      const errorMessage =
        err.response?.data?.error ||
        err.message ||
        "Something went wrong with getStravaProfileCol.";
      return rejectWithValue(errorMessage);
    }
  }
);

// Note: https://appwrite.io/docs/products/databases/pagination
export const getStravaActivityCol = createAsyncThunk(
  "strava/getStravaActivityCol",
  async ({ userId }, { rejectWithValue }) => {
    try {
      if (!userId) {
        throw new Error("User id not provided to getStravaActivityCol.");
      }

      const allDocuments = [];
      const limit = 25; // Max limit per request
      let offset = 0;
      let hasMore = true;

      while (hasMore) {
        const response = await databases.listDocuments(
          process.env.REACT_APP_APPWWRITE_DB_ID, // databaseId
          process.env.REACT_APP_APPWWRITE_STRAVA_ACTIVITY_ID, // collectionId
          [
            Query.equal("appUserId", userId),
            Query.limit(limit),
            Query.offset(offset),
          ]
        );
        console.log("getStravaActivityCol: response: ", response);

        allDocuments.push(...response.documents);

        // Note: if current response less than limit no more docs to fetch
        if (response.documents.length < limit) {
          hasMore = false;
        } else {
          offset += limit; // Note: increment offset by limit
        }
      }

      console.log("getStravaActivityCol: allDocuments: ", allDocuments);
      return { total: allDocuments.length, documents: allDocuments }; // Return all documents
    } catch (err) {
      console.log("getStravaActivityCol: err: ", err);
      const errorMessage =
        err.response?.data?.error ||
        err.message ||
        "Something went wrong with getStravaActivityCol.";
      return rejectWithValue(errorMessage);
    }
  }
);

// TODP Get Athlete Stats (getStats) ... for a detailed user profile page ...
// https://developers.strava.com/docs/reference/#api-Activities-getLoggedInAthleteActivities

export const stravaSlice = createSlice({
  name: "strava",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // getAccessRefresh
      .addCase(getAccessRefresh.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getAccessRefresh.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        state.athleteInfo = action.payload; // Save the athlete info from the backend
      })
      .addCase(getAccessRefresh.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      })
      // getStravaActivities
      .addCase(getStravaActivities.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getStravaActivities.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        state.activities = action.payload; // Save the activities data from the backend
      })
      .addCase(getStravaActivities.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      })
      // getStravaProfileCol
      .addCase(getStravaProfileCol.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getStravaProfileCol.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        state.athleteInfo = action.payload; // Save profile info
      })
      .addCase(getStravaProfileCol.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      })
      // getStravaActivityCol
      .addCase(getStravaActivityCol.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getStravaActivityCol.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        state.activities = action.payload.documents; // Save activities
        // state.totalActivities = action.payload.total;
      })
      .addCase(getStravaActivityCol.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      });
  },
});

export const selectAthlete = (state) => state.strava.athleteInfo;
export const selectActivities = (state) => state.strava.activities;

export default stravaSlice.reducer;
