import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { initUser, selectIsLoggedIn } from "../../features/user/userSlice";

function RequireAuth() {
  // const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  console.log("RequireAuth: isLoggedIn: ", isLoggedIn);

  const location = useLocation();
  console.log("RequireAuth: location: ", location);

  return isLoggedIn ? (
    <Outlet />
  ) : (
    // Note:  replace the current entry in browser's history stack with sign-in; thus can't click back
    //        to state.from add the current page
    //        can use state.from.pathname to redirect user to desired page after they login
    <Navigate to="/sign-in" state={{ from: location }} replace />
  );
}

export default RequireAuth;
