import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendPwdResetEmail } from "../../features/user/userSlice";

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";

function ForgotPassword({ open, handleClose, handleOpen }) {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const url =
    process.env.REACT_APP_NODE_ENV === "production"
      ? "https://sunnycodesapp.com/reset"
      : "http://localhost:3000/reset";

  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("ForgotPassword submitted");

    let isValid = true;

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }

    if (!isValid) {
      return;
    }

    try {
      const response = await dispatch(
        sendPwdResetEmail({ email, url })
      ).unwrap();
      console.log("ForgotPassword response: ", response);
      setSuccess(true);
    } catch (error) {
      console.error(error);
      setSuccess(true);
      // setEmailError(true);
      // setEmailErrorMessage(error.message || "Failed to send reset email.");
    }
  };

  const handleDialogClose = () => {
    setSuccess(false);
    handleClose();
  };

  return (
    <>
      {success ? (
        <Dialog
          open={open}
          onClose={handleDialogClose}
          PaperProps={{
            component: "form",
            onSubmit: handleSubmit,
            sx: { backgroundImage: "none" },
          }}
        >
          <DialogTitle>Reset password</DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
            }}
          >
            <Typography variant="body2" color="success.main">
              A password reset link has been sent if the email exists in our
              system. Please check your inbox.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ pb: 3, px: 3 }}>
            <Button
              onClick={() => {
                setSuccess(false);
                setEmail("");
                handleOpen();
              }}
            >
              Resend
            </Button>
            <Button onClick={handleClose}>Done</Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={open}
          onClose={handleDialogClose}
          PaperProps={{
            component: "form",
            onSubmit: handleSubmit,
            sx: { backgroundImage: "none" },
          }}
        >
          <DialogTitle>Reset password</DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
            }}
          >
            <DialogContentText>
              Enter your account&apos;s email address, and we&apos;ll send you a
              link to reset your password.
            </DialogContentText>
            <FormControl fullWidth>
              <TextField
                autoFocus
                required
                id="email"
                name="email"
                placeholder="Email address"
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                error={emailError}
                helperText={emailError && emailErrorMessage}
                fullWidth
              />
            </FormControl>
          </DialogContent>
          <DialogActions sx={{ pb: 3, px: 3 }}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

ForgotPassword.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ForgotPassword;
