import React from "react";
import { useDispatch } from "react-redux";
import { sendVerificationEmail } from "../../features/user/userSlice";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";

function SendVerifyEmail() {
  const dispatch = useDispatch();

  const handleResendVerification = async (event) => {
    event.preventDefault();
    try {
      // Note: already sent verification email in SingUp.js
      // TODO currently redirects to /sign-in after dispatching sendVerificationEmail
      await dispatch(
        sendVerificationEmail(
          process.env.REACT_APP_NODE_ENV === "production"
            ? "https://sunnycodesapp.com/verify-email"
            : "http://localhost:3000/verify-email"
        )
      ).unwrap();
      alert("Verification email resent. Please check your inbox.");
    } catch (err) {
      alert("Failed to send verification email. Please try again.");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "background.default",
      }}
    >
      <Card
        sx={{
          maxWidth: 400,
          p: 3,
          boxShadow:
            "0px 4px 20px rgba(0, 0, 0, 0.1), 0px 8px 40px rgba(0, 0, 0, 0.05)",
        }}
      >
        <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h5" gutterBottom>
            Verify Your Email
          </Typography>
          <Typography variant="body2">
            We have sent a verification email. Please check your inbox and click
            the link in the email to verify your account.
          </Typography>
          <Button
            variant="contained"
            onClick={handleResendVerification}
            sx={{ mt: 2, textTransform: "none" }}
          >
            Resend Verification Email
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
}

export default SendVerifyEmail;
