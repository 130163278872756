import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectVerifiedEmail } from "../../features/user/userSlice";

// Note: after clicking link the emailVerification is false until completeVerification.fulfilled
// TODO maybe need to wait till idle status .... isLoading as in RequireAuth

function RequireVerification(props) {
  // TODO if reload page Redux store state is cleared
  const isVerified = useSelector(selectVerifiedEmail);
  console.log("RequireVerification: isVerified: ", isVerified);
  if (!isVerified) {
    return <Navigate to="/send-verify-email" replace />;
  } else {
    return <Outlet />;
  }
}

export default RequireVerification;
