import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  completeVerification,
  selectVerifiedEmail,
} from "../../features/user/userSlice";

import { useSearchParams, useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";

function CompleteEmailVerify() {
  // Note: component wil render when redux store updates and selector return new value
  const isVerified = useSelector(selectVerifiedEmail);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      console.log("CompleteEmailVerify: useEffect: verifyEmail");
      const userId = searchParams.get("userId");
      const secret = searchParams.get("secret");

      if (!userId || !secret) {
        setError(true);
        setErrorMessage("Invalid verification link.");
        setLoading(false);
        return;
      }

      try {
        const response = await dispatch(
          completeVerification({ userId, secret })
        ).unwrap();
        console.log("CompleteEmailVerify: response: ", response);
        setLoading(false);
      } catch (err) {
        setError(true);
        setErrorMessage(
          err.message || "Failed to verify email. Please try again."
        );
        setLoading(false);
      }
    };

    verifyEmail();
    // Note: component should only mount after successful registration and after user clicks verify email link
  }, []);

  // Note: component re-renders when state changes!
  const content = () => {
    if (error && !loading) {
      return (
        <>
          <Typography variant="body2" color="error">
            {errorMessage}
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate("/send-verify-email", { replace: true })}
            sx={{ mt: 2 }}
          >
            Try again.
          </Button>
        </>
      );
    } else {
      if (loading) {
        return (
          <Typography variant="body2">
            Verifying your email... Please wait.
          </Typography>
        );
      }
      if (!loading && isVerified) {
        return (
          <>
            <Typography variant="body2">
              Your email has been successfully verified.
            </Typography>
            <Button
              variant="contained"
              onClick={() => navigate("/registered/home", { replace: true })}
              sx={{ mt: 2 }}
            >
              Go to dashboard
            </Button>
          </>
        );
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "background.default",
      }}
    >
      <Card
        sx={{
          maxWidth: 400,
          p: 3,
          boxShadow:
            "0px 4px 20px rgba(0, 0, 0, 0.1), 0px 8px 40px rgba(0, 0, 0, 0.05)",
        }}
      >
        <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h5" gutterBottom>
            Complete Email Verification
          </Typography>
          {content()}
        </CardContent>
      </Card>
    </Box>
  );
}

export default CompleteEmailVerify;
